body {
  background: var(--primary-color);
  color: var(--text-color);
  border-color: var(--border);
  height: 100vh; 
}


.font-fantasy{
  font-family: fantasy;
}


/* //screen_container */
@media screen and (max-width: 480px){ 
  .screen_container{
      margin-left: auto;
      margin-right: auto;
      max-width: 460px
;    }
}

@media screen and (min-width: 481px) and (max-width: 768px){ 
  .screen_container{
    margin-left: auto;
    margin-right: auto;
      max-width: 7680px
  }
}

@media screen and (min-width: 769px) and (max-width: 1299px){ 
  .screen_container{
    margin-left: auto;
    margin-right: auto;
      max-width: 1299px
  }
}

@media screen and (min-width: 1300px){ 
  .screen_container{
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
  }
}




.custom-scrollbar,
.custom-scrollbar-w,
.custom-scrollbar_2,
.custom-scrollbar-p {
  overflow: auto;
  position: relative; 
  border-radius: 5px;
}
.custom-scrollbar-p { 
  margin: 5px;
}

 
.custom-scrollbar .content ,
.custom-scrollbar-w .content,
.custom-scrollbar_2 .content,
.custom-scrollbar-p .content {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}


.custom-scrollbar::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.custom-scrollbar-w::-webkit-scrollbar,
.custom-scrollbar_2::-webkit-scrollbar,
.custom-scrollbar-p::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}


.custom-scrollbar::-webkit-scrollbar-thumb,
.custom-scrollbar_2::-webkit-scrollbar-thumb,
.custom-scrollbar-p::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-bg);;
  border-radius: 6px;

}
.custom-scrollbar-w::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 6px;
}


.custom-scrollbar::-webkit-scrollbar-track,
.custom-scrollbar-w::-webkit-scrollbar-track,
.custom-scrollbar_2::-webkit-scrollbar-track {
  background-color: #fff0;
  border-radius: 6px;
}
.custom-scrollbar-p::-webkit-scrollbar-track {
  background-color: var(--primary-bg);
  border-radius: 6px;
}


.custom-scrollbar::-webkit-scrollbar-thumb:hover,
.custom-scrollbar_2::-webkit-scrollbar-thumb:hover,
.custom-scrollbar-p::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-bg);
  border-radius: 6px;
}
.custom-scrollbar-w::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff;
  border-radius: 6px;
}

 


/* //light theme */
:root {
  --primary-color: #fff;
  --primary-bg: linear-gradient(270deg, #F57B78, #B6325F);
  --secondary-color: rgb(230, 230, 230); 
  --scrollbar-bg: #28292A; 

  --color-accent: #F57B78;  

  /* --text-color: #212121;  */
  --text-color: #fff; 

  --border: rgba(102, 115, 141, 0.3);  
  --border-error: rgba(212, 67, 67, 0.79);  

  --button-color: var(--color-accent);  
  --button-color-2: #000000;  
  --disable-btn-color: #ee48646e;  

  --color-03: rgb(0, 0, 0, 0.3);
  --color-04: rgb(0, 0, 0, 0.4);
  --color-05: rgb(0, 0, 0, 0.5);
  --color-06: rgb(0, 0, 0, 0.6);
  --color-07: rgb(0, 0, 0, 0.7);
  --color-08: rgb(0, 0, 0, 0.8);
  --color-09: rgb(0, 0, 0, 0.9);
  --color-06-o: rgb(255, 255, 255, 0.6);
}


/* //dard theme */
.dark-mode { 
  --primary-color: #1F1F1F; 
  --primary-bg: #1d1f25; 
  --secondary-color: #28292A;  
  --scrollbar-bg: #ffffff;  

  --color-accent: #EE4864;  

  --text-color: #ffffff;  

  --border: rgba(171, 175, 183, 0.8);  
  --border-error: rgba(212, 67, 67, 0.79);  

  --button-color: var(--color-accent);  
  --button-color-2: #000000;  
  --disable-btn-color: #5d5252; 

  --color-03: rgb(255, 255, 255, 0.3);
  --color-04: rgb(255, 255, 255, 0.4);
  --color-05: rgb(255, 255, 255, 0.5);
  --color-06: rgb(255, 255, 255, 0.6);
  --color-07: rgb(255, 255, 255, 0.7);
  --color-08: rgb(255, 255, 255, 0.8);
  --color-09: rgb(255, 255, 255, 0.9);
  --color-06-o: rgb(0, 0, 0, 0.6);
}
   


.bg-fj{ background: var(--primary-bg); }


.flex-row-rev{ flex-direction: row-reverse; }

.color-primary{ background-color:  var(--primary-color); }
.color-secondary{ background-color:  var(--secondary-color); }
.color-ter{ background-color:  var(--ter-color); }
.color-accent{ background-color:  var(--color-accent); }
.text-color{ color:  var(--text-color); }
.border-color{ border-color:  var(--border); }

.c-shadow{ box-shadow: 0 0 2px var(--text-color); }
 
.color-dim-5{ color: var(--color-05); }
.color-dim-6{ color: var(--color-06); }
.color-dim-7{ color: var(--color-07); }
.color-dim-8{ color: var(--color-08); }
.color-dim-9{ color: var(--color-09); }

.font-bold-500{font-weight: 500;}
.font-bold-600{font-weight: 600;}


.item-bg{
  background-color: var(--color-02);
}

.item-bg:hover{
  background-color: var(--color-02);
  cursor: pointer;
}

.item-bg-2:hover{
  background-color: var(--color-02);
  cursor: pointer;
}



.item-bg-3{
  background-color: var(--color-02);
}

.item-bg-3:hover{
  background-color: var(--color-03);
  cursor: pointer;
}



























































/* //buton */

/* //button common part */
.btn_1 {
  width: 100%;
  height: 48px;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;

  background-color: var(--button-color);
  background-image: linear-gradient(to bottom, var(--button-color), var(--button-color2));
  box-shadow: 
  0px 2px 3px rgba(0, 0, 0, 0.2) , 
  inset 0px 1.5px 8px rgba(255, 255, 255, 0.5),
  inset 0px 1px 8px rgba(0, 0, 0, 0.1), 
  inset 0px -1px 3px rgba(0, 0, 0, 0.3);
}

/* //button disable */
.btn_1.disabled{
  cursor: not-allowed;
  color: white;
  background-color: var(--disable-btn-color);
  background-image: none;
} 






.button_1{
  min-height: 45px;
  min-width: 130px;
  border-radius: 20px;
  cursor: pointer;
  background-color: #2c4188;
  border: 1px solid rgb(17, 51, 165)
}

.button_1:hover {
  background-color: rgb(33, 56, 131)
}

.button_2{
  min-height: 45px;
  min-width: 130px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #52576B;
  border: 1px solid #3e4150;
}

.button_2:hover {
  background-color: #555c77;
}

.button_3{
  font-size: 122px;
  cursor: pointer;
  background-color: #52576B;
  border: 1px solid #3e4150;
}



.button_4{
  font-size: 14px;
  cursor: pointer;
  padding: 3px 5px;
  border-radius: 7px;
  background-color: #52576B;
  border: 1px solid #3e4150;
}

.button_4:hover{
  cursor: pointer;
  background-color: #464e6b;
  border: 1px solid #3e4150;
}



.button_2:hover {
  background-color: #555c77;
}











































.fdfdsfs3 { 
  opacity: 0;
  animation: fadeInOut 2s infinite; }


@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}




.fdfdsfs2 { 
  opacity: 0.5;
  animation: fadeInOut2 2s infinite; }


@keyframes fadeInOut2 {
  0%, 100% {
    opacity: 0.7;
    scale: 0.9;
  }
  50% {
    opacity: 1;
    scale: 1;
  }
}






























/* //input */


input:focus { border: 1.5px solid var(--input_select-b) !important;}
textarea:focus { border: 1.5px solid var(--input_select-b) !important; }
 
input::selection {
  background-color: var(--input-t-select);  
}

body::selection { background-color: var(--input-t-select); }
input:focus { outline: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--primary-color) inset !important;
  -webkit-text-fill-color: var(--text-color) !important;
}

input::after {
  content: "";
  position: absolute;
  background-color: rgb(0, 0, 0);
}

.input_design-2{
  height: 40px !important;
}

/* //input layout 1*/ 
.input_design , .input_design-2{
    display: block;
    width: 100%;
    overflow: visible;

    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px;
    height: 48px;
    border-radius: 5px;
    font-size: 16px;
    line-height: 1.31;
    z-index: 0; 
    border: 1.5px solid var(--border-color) !important;
    color: var(--text-color) !important;
    background-color: var(--secondary-color) !important;
  }


  .label_visible::before {
    content: '';  
    position: absolute;  
    top: 42%; 
    left: 0; 
    right: 0; 
    border-top: 2.5px solid var(--secondary-color);
    z-index: -1; 
  }


  .input_label{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    line-height: 46px;
    color: var(--text-color);
    font-size: 14px;
    transition: all .1s ease-in-out;
  } 

  .input_label_2{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    line-height: 38px;
    color: var(--text-color);
    font-size: 13px;
    transition: all .1s ease-in-out;
  } 


  .label_visible{
    margin: 0 15px;
    padding: 0 3px;
    font-size: 12px;
    color: var(--input_select-b); 
    top: -6px;
    line-height: 13px;
    height: 14px;
    width: auto;
    z-index: 1;
  } 
  
  .label_input_c_b{
    color: var(--input_deselect);
  }

  input::selection{ border: 1px solid var(--input_select-b); }

   
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  } 
 
 



/* //input custom */ 
.country-code {
  position: absolute;
  height: 48px;
  line-height: 48px; 
  font-weight: bold;

  top: 0;
  left: 15px;
  font-size: 16px;
  opacity: .6;
  color: #465166;
}
  




#phone_no{
  padding-left: 55px;
}
 

.verify {
  position: absolute;
  height: 48px;
  line-height: 48px;

  top: 0;
  right: 15px;
  font-size: 14px;
  font-weight: 700;
  color: var(--color-accent);
  cursor: default;
}

.verify.disabled{
  color: rgba(70,81,102,.3);
  cursor: not-allowed;
}

.verify:hover{
  cursor: pointer;
}

.error-text{   
  position: absolute;
  bottom: -16px;
  padding: 0px 5px;
  line-height: 10px;
  font-size: 12px;
  color: #c80039;
}























/* page not found */
.pnf_bg{
  height: 100vh;
  font-family: "Courier" , "sans-serif"; 
}
 

.pnf_button{
  border: 0px solid ;
  border-bottom: 2px solid rgb(62, 62, 62);
  background-color: rgb(88,150,203);
  box-shadow: 2px 2px 2px rgba(88,150,203, 0.2); 
}

.pnf_button:hover{
  background-color: rgb(88,150,203, 0.8);
}


.font_1{
  font-size: 180px;
  font-weight: bold;
  text-shadow: 4px 4px 8px rgba(28, 107, 233, 0.5);
}

.font_2{
  font-size: 40px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}


















.slide-up {
  animation: slideUp 1s ease backwards;
  overflow: hidden;
}

@keyframes slideUp {
  from {
    max-height: 80vh;
    opacity: 0;
    transform: scaleY(0.7);
    transform: scaleX(0.7);
  }
  to {
    max-height: 100vh;
    opacity: 1;
    transform: scaleY(1);
    transform: scaleX(1);
  }
}



.slide-down {
  animation: slideDown 1s ease forwards;
  overflow: hidden;
}

@keyframes slideDown {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 100vh;
    opacity: 1;
  }
}

