@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Arial', 'Helvetica', 'sans-serif' 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
  




.px-9 { padding-left: 2.25rem; padding-right: 2.25rem; }
.px-12 { padding-left: 3rem; padding-right: 3rem;  }
.px-15 { padding-left: 3.75rem; padding-right: 3.75rem; }
.px-18 { padding-left: 4.5rem; padding-right: 4.5rem; }
.px-21 { padding-left: 5.25rem; padding-right: 5.25rem; }
.px-24 { padding-left: 6rem ; padding-right: 6rem; }
.px-27 { padding-left: 6.75rem; padding-right: 6.75rem; }
.px-30 { padding-left: 7.5rem; padding-right: 7.5rem; }


.pl-3 { padding-left: 0.75rem;}
.pl-6 { padding-left: 1.5rem;}
.pl-9 { padding-left: 2.25rem;}
.pl-12 { padding-left: 3rem;}
.pl-15 { padding-left: 3.75rem;}
.pl-18 { padding-left: 4.5rem;}
.pl-21 { padding-left: 5.25rem;}
.pl-24 { padding-left: 6rem; }
.pl-27 { padding-left: 6.75rem;}
.pl-30 { padding-left: 7.5rem;}



.m-0-i{
  margin: 0 !important;
}




.progress_1{
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid #333; 
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;  
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} 











.bg_color_1{
  background-color: #141926;
}



.layout_1_ {
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 15px; 
  border: 1.2px solid #62687e89;
  transition: box-shadow 0.3s ease;

  box-shadow: inset 0px 1px 1px 1px #00000067;
}


.layout_1_:hover {
  cursor: pointer;
  scale: 1.01;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1), 0 0.5rem 2rem rgba(0, 0, 0, 0.2);
}


.layout_2_ { 
  transition: box-shadow 0.3s ease;
}


.layout_2_:hover {
  cursor: pointer;
  scale: 1.03;
  background-color: rgba(255, 255, 255, 0.102);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1), 0 0.5rem 2rem rgba(0, 0, 0, 0.2);
}


.layout_3_ { 
  border: 1px solid white;
  transition: box-shadow 0.3s ease;
}


.layout_3_:hover {
  cursor: pointer;
  background-color: rgba(41, 41, 41, 0.102);
  box-shadow: 0 1px 1px rgba(0,0,0, 0.2);
}





.two-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2; /* Number of lines to show */
  text-overflow: ellipsis;
}


.border_1{
  border: 1px solid #0000001f;
}



aside , .aside{
  top: 25px;
  position: sticky;
}

 


    
 
 















 






 

.card-slider {  
  width: fit-content; 
  list-style-type: none; 
}

.card_moible {
  width: 135px;
  height: 240px;   
}
 
.card_full {
  width: 480px;
  height: 270px;   
}
 


.scale-300{
  scale: 300%;
}